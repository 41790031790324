<template>
    <div>
      <table class="table">
      <thead>
        <tr>
          <th colspan="3">
            <h2>
              Maintenance
            </h2>
          </th>
          <th>
              <h3>Prochaine : {{ nextdtsem }}</h3>
          </th>
          <th v-if="nbmois==6" colspan="3">
              <h3>Prochaine : {{ nextdtann }}</h3>
          </th>
        </tr>
      </thead>

      <thead>
        <tr>
          <th scope="col">Ref</th>
          <th scope="col">Rubrique</th>
          <th scope="col">Prestation</th>
          <th v-if="nbmois==6" scope="col">Semestrielle</th>
          <th scope="col">Annuelle</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="presta.id" v-for="presta in prestation">
          <template v-if="presta.ref!=''">
          <td><strong>{{ presta.ref }}</strong></td>
          <td colspan="2"><strong>{{ presta.rubrique }}</strong></td>
        </template>
        <template v-if="presta.ref==''">
          <td>&nbsp;</td>
          <td>{{ presta.idsub }}</td>
          <td>{{ presta.presta }}</td>
          <td v-if="nbmois==6"><i v-if="presta.semestriel==1" class="fa fa-check vert"></i></td>
          <td><i class="fa fa-check vert"></i></td>
        </template>
        </tr>
      </tbody>
      </table>

      <br/>
  <br/>
  <br/>
  <br/>
    </div>

</template>

  <script>
  import store from "../store";
  import { getPrefix, executeApi, selectApi } from "../lib/api.js";
  import Vue from "vue";
  import moment from "moment";

  export default {
    name: "Periode",
    store: store,
    data() {
      return {
        countpresta: 0,
        prestation: [],
        nbmois: store.state.periode,
        lasttype: -1,
        lastdt: moment().format("DD/MM/YYYY"),
        nextdtsem: moment().format("DD/MM/YYYY"),
        nextdtann: moment().format("DD/MM/YYYY")
      };
    },
    methods: {
        refresh() {
      let id = store.state.piste;
      let sql = ""

        sql =
        "SELECT p.id,p.idtype,p.idsub,p.libelle as presta,t.ref,t.libelle as rubrique,p.semestriel FROM prestations as p LEFT JOIN typepresta as t ON t.id=p.idtype" + 
        " WHERE semestriel=1 OR annuel=1 OR (annuel=0 and semestriel=0 and mensuel=0) ORDER BY p.idtype ASC,p.idsub ASC"

        selectApi(this, sql).then(
        (response) => {
          this.countpresta = response.data.count;
          this.prestation = [];
          let lasttype = -1;
          let pid = 1;
          response.data.result.forEach(item => {
            if (lasttype != item.idtype) {
              const p = {
                id: pid,
                idtype: item.idtype,
                idsub: item.idsub,
                presta: item.presta,
                ref: item.ref,
                rubrique: item.rubrique,
                semestriel: item.semestriel
              }
              this.prestation.push(p)
              pid++;
            }
            lasttype = item.idtype
            item.id=pid
            item.ref=""
            pid++;
            this.prestation.push(item)
          });
                },
        (response) => {
        }
      );

      sql = "SELECT type,dt FROM maintenance WHERE idpiste="+id+" AND (type=0 OR type=1) ORDER BY dt DESC LIMIT 1"

        selectApi(this, sql).then(
        (response) => {
          if (response.data.count > 0) {
            this.lasttype = response.data.result[0].type;
            this.lastdt = moment(response.data.result[0].dt)
            if (this.nbmois == 6) {
                if (this.lasttype == 0) {
                  this.nextdtann = this.lastdt.add(6, 'M').format("DD/MM/YYYY")
                  this.nextdtsem = this.lastdt.add(6, 'M').format("DD/MM/YYYY")
                }
                if (this.lasttype == 1) {
                  this.nextdtsem = this.lastdt.add(6, 'M').format("DD/MM/YYYY")
                  this.nextdtann = this.lastdt.add(6, 'M').format("DD/MM/YYYY")
                }
            }
            if (this.nbmois == 12) {
              this.nextdtann = this.lastdt.add(12, 'M').format("DD/MM/YYYY")
            }
          }
        },
        (response) => {
        }
      );
    }

    },
    mounted() {
      if (store.state.typeUser == 0) {
        this.$router.push({ name: "login" });
      }
      this.refresh();
    },
  };
  </script>
  
  <style lang="scss">
  @import "src/assets/scss/design.scss";

  .vert {
    color: green;
  }
  </style>